import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import { useForm } from 'antd/es/form/Form'
import { EditForm } from 'components'
import { SelectRequiredRule } from 'components/EditModel/utils'
import { useQuery } from 'hooks/useQuery'
import _ from 'lodash'
import moment from 'moment'
import { QuestionBindingGroup } from 'pages/exam-papers/MarkingSettingDialog/QuestionBindingGroup'
import { TeacherSelect } from 'pages/exam-papers/MarkingSettingDialog/TeacherSelect'
import { MultiTeacherListSelectorForFanya } from 'pages/exam-papers/MarkingSettingDialog/TeacherSelect/MultiSelector'
import { TeacherSelectorForFanya } from 'pages/exam-papers/MarkingSettingDialog/TeacherSelect/Selector'
import {
  MarkingSettingProvider,
  useMarkingSettingContent
} from 'pages/exam-papers/MarkingSettingDialog/useMarkingSettingState'
import { useQuestionBindGroupContent } from 'pages/exam-papers/MarkingSettingDialog/useQuestionBindGroup'
import {
  MarkingSettingEditProvider,
  useMarkingSettingEditContext
} from 'pages/exam-papers/store'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ResizeObserver from 'resize-observer-polyfill'
import { Paper, QuestionBindGroup } from 'typing'
import { requestAndSaveFile } from 'utils/exporter'
import { flattenGroupedQuestions } from 'utils/question'
import { APP_SCOPE, MarkingMode } from '../../config'
import { GET, POST, defaultErrorHandler } from '../../core/request'
import {
  PaperMarkingSettingDistributeStatus,
  SteppedScoreSetting
} from '../../typing.paper'
import { MarkingStatusDialog } from './components/MarkingStatusDialog'
import { SettingSyncDialogVisible } from './components/SettingSyncDialog'
import { JudgeFilterFormItem } from './components/judge-filter/JudgeFilterFormItem'
import { JudgeTeacherFormItem } from './components/judge-filter/JudgeTeacherFormItem'
import { StepScoreFormItem } from './components/step-score/StepScoreFormItem'
import './index.less'

const { confirm } = Modal
const Text = Typography.Text

const Page: FC = props => {
  const queryString = useQuery<{ preview: any }>()
  const routeParams = useParams<{ pid }>()

  const {
    teacherList,
    paperQuestions,
    distributeStatus,
    formFieldReadonly,

    // StepScoreSetting on submit
    stepScoreSettingEnable,
    stepScoreSettingItems
  } = useMarkingSettingContent()
  const { questionBindGroup } = useQuestionBindGroupContent()

  const edit = useMarkingSettingEditContext()
  const [error, setError] = useState<string>()
  const [form] = useForm()
  const currentPaper: Paper.Paper = edit.data

  async function fetchSetting() {
    try {
      await edit.onEdit({ id: routeParams.pid } as any)
    } catch (err) {
      const e = err as { message: string; handled: boolean }
      setError(e.message)
      if (e.handled) return
      Modal.error({
        title: e.message
      })
    }
  }
  useEffect(() => {
    fetchSetting()
  }, [])

  const readonly = useMemo(() => {
    return !!error || formFieldReadonly
  }, [error, formFieldReadonly])

  // For dynamic form items
  const [currentMarkingMode, setCurrentMarkingMode] = useState<MarkingMode>()
  useEffect(() => {
    setCurrentMarkingMode(edit.data.review_mode)
  }, [edit.data])

  const [reviewModeEnable, setReviewModeEnable] = useState(false)
  useEffect(() => {
    if (edit.data.inspector?.length) {
      setReviewModeEnable(true)
    }
  }, [edit.data])

  //#region edit
  function validate(value) {
    const bindQuestionCount = _.size(
      _.flatMap(questionBindGroup, q => q.questions)
    )
    const paperQuestionsFlat = flattenGroupedQuestions(paperQuestions)
    const isAllQuestionBind = bindQuestionCount === paperQuestionsFlat.length

    if (!isAllQuestionBind) {
      Modal.warn({
        title: `还有${
          paperQuestionsFlat.length - bindQuestionCount
        }题未加入到试题分组`
      })
      return false
    }

    if (
      typeof currentMarkingMode === 'number' &&
      [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(currentMarkingMode)
    ) {
      // 题目下的教师判卷小组
      // 长度大于1
      const isAllQuestionGroupHasMoreThenOneSubGrouped = _.every(
        questionBindGroup,
        g => g.groupedTeachers && g.groupedTeachers.length > 1
      )
      if (!isAllQuestionGroupHasMoreThenOneSubGrouped) {
        Modal.warn({
          title: '一个题组至少需要两个判卷小组'
        })
        return false
      }
    }

    const isAllGroupBindTeacher = _.every(questionBindGroup, g => {
      if (
        typeof currentMarkingMode === 'number' &&
        [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(currentMarkingMode)
      ) {
        // 题目下的教师判卷小组
        return (
          !!g.groupedTeachers?.length &&
          _.every(g.groupedTeachers, tg => !!tg.length)
        )
      }
      return !!g.teachers.length
    })
    if (!isAllGroupBindTeacher) {
      Modal.warn({
        title: '还有分组未添加判卷教师'
      })
      return false
    }

    if (!value.review_time) {
      Modal.warn({
        title: '请选择判卷开始时间'
      })
      return false
    }

    if (
      moment(value.review_end_time).isSameOrBefore(moment(value.review_time))
    ) {
      Modal.warn({
        title: '判卷结束时间必须在判卷开始时间之后'
      })
      return false
    }

    const groupTeacher = _.flatMap(questionBindGroup, g => g.teachers)

    // const judgeTeacher = (value as Paper.PaperMarkingSettingFormData).judge
    // if (value.review_mode === MarkingMode.AUDIT && judgeTeacher) {
    //   if (groupTeacher.find(it => it.uid === judgeTeacher.uid)) {
    //     Modal.warn({
    //       title: '仲裁人员不能与判卷教师重复'
    //     })
    //     return false
    //   }
    // }

    const reviewTeachers = (value as Paper.PaperMarkingSettingFormData)
      .inspector
    if (reviewModeEnable && reviewTeachers?.length) {
      if (reviewTeachers.some(t => groupTeacher.find(it => it.uid === t.uid))) {
        Modal.warn({
          title: '判卷负责人不能与判卷教师重复'
        })
        return false
      }
    }

    // const examEndTime = moment(edit.data.end_time)
    // const isExamEnded = moment().isAfter(examEndTime)
    // const beginReviewMin = isExamEnded
    //   ? moment().add(1, 'hours')
    //   : moment(edit.data.end_time).add(1, 'hour')
    // if (value.review_time && value.review_time.isBefore(beginReviewMin)) {
    //   if (isExamEnded) {
    //     Modal.warn({
    //       title: '判卷开始时间必须在当前时间之后1小时'
    //     })
    //   } else {
    //     Modal.warn({
    //       title: '判卷开始时间必须在考试结束之后1小时',
    //       content: (
    //         <div>
    //           考试结束时间 <br />
    //           {moment(edit.data.end_time).format(
    //             `${FORMATTER_DATE} ${FORMATTER_TIME}`
    //           )}
    //         </div>
    //       )
    //     })
    //   }
    //   return false
    // }

    return true
  }

  function transformFormDataForSubmit(data: Paper.PaperMarkingSettingFormData) {
    data.teachers = teacherList

    const isGroupedTeachers = [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(
      data.review_mode
    )
    data.quest_group = _.reduce<QuestionBindGroup, typeof data.quest_group>(
      questionBindGroup,
      (res, g) => {
        if (isGroupedTeachers) {
          res.push({
            quests: _.map(g.questions, 'id'),
            teachers: _.map(g.groupedTeachers, tg => _.map(tg, 'uid'))
          })
        } else {
          res.push({
            quests: _.map(g.questions, 'id'),
            teachers: _.map(g.teachers, 'uid')
          })
        }
        return res
      },
      []
    )

    // Judge
    if (
      data.review_mode === MarkingMode.AUDIT &&
      data.judge_filter_in_percent
    ) {
      data.judge_filter = [
        { type: 2, value: data.judge_filter_in_percent / 100 }
      ]
    }
    data.judge_filter_in_percent = undefined

    // Review
    if (data.review_mode !== MarkingMode.GROUP_FLOW || !reviewModeEnable) {
      data.inspector = []
    }

    data.review_time = moment(data.review_time).format('YYYY-MM-DD HH:mm:00')
    data.review_end_time = moment(data.review_end_time).format(
      'YYYY-MM-DD HH:mm:00'
    )

    data.step_score_setting = JSON.stringify({
      enable: stepScoreSettingEnable ?? false,
      items: stepScoreSettingItems ?? []
    } as SteppedScoreSetting)

    return data
  }

  async function onSubmit() {
    let value
    try {
      value = await form.validateFields()
    } catch (e) {
      Modal.warn({
        title: '请检查表单是否未填写完整'
      })
      return
    }
    if (!validate(value)) {
      return
    }
    const data = { ...value } as Paper.PaperMarkingSettingFormData
    transformFormDataForSubmit(data)

    try {
      await edit.onSubmit(data)
      setTimeout(() => {
        postMessagesToKaoShi({ type: 'finish' })
      }, 1500)
    } catch (e) {
      defaultErrorHandler(e as any)
    }
  }

  // Editable review time
  const [reviewEndSubmitBtnVisible, setReviewEndSubmitBtnVisible] = useState(
    false
  )
  const [reviewEndSubmitLoading, setReviewEndSubmitLoading] = useState(false)
  function onReviewEndTimeChange() {
    if (edit.data.has_setting) setReviewEndSubmitBtnVisible(true)
  }
  async function onSubmitReviewEndTime() {
    const value = form.getFieldsValue()
    if (!validate(value)) {
      return
    }
    try {
      setReviewEndSubmitLoading(true)
      await POST(
        `/${APP_SCOPE.isInFanYa ? 'fanya' : 'city'}/exam/paper/${
          edit.params.id
        }/review_end_time`,
        {
          data: {
            review_end_time: moment(value.review_end_time).format(
              'YYYY-MM-DD HH:mm:00'
            )
          }
        }
      )
      Modal.success({
        title: '判卷结束时间修改成功',
        onOk: () => {
          postMessagesToKaoShi({ type: 'finish' })
        }
      })
    } catch (e) {
      defaultErrorHandler(e as any)
    } finally {
      setReviewEndSubmitLoading(false)
    }
  }

  // Editable score sign mode
  const [
    scoreSignModeSubmitBtnVisible,
    setScoreSignModeSubmitBtnVisible
  ] = useState(false)
  const [scoreSignModeSubmitLoading, setScoreSignModeSubmitLoading] = useState(
    false
  )
  function onScoreSignModeChange() {
    if (edit.data.has_setting) setScoreSignModeSubmitBtnVisible(true)
  }
  async function onScoreSignModeSubmit() {
    try {
      setScoreSignModeSubmitLoading(true)
      const value = (await form.validateFields()) as Paper.PaperMarkingSettingFormData
      transformFormDataForSubmit(value)
      await edit.onSubmit(value)
      setTimeout(() => {
        location.reload()
      }, 2000)
    } finally {
      setScoreSignModeSubmitLoading(false)
    }
  }

  //#endregion

  function onCancel() {
    postMessagesToKaoShi({ type: 'cancel' })
  }

  function postMessagesToKaoShi(obj: { type: string; payload? }) {
    console.log('postMessage', obj)
    window.parent.postMessage({ source: 'exam-paper-setting', ...obj }, '*')
  }

  //#region resize
  const resizeObserver = useRef<ResizeObserver>()
  const roEl = useRef<HTMLDivElement>(null)
  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      if (entries[0]) {
        postMessagesToKaoShi({
          type: 'resize',
          payload: entries[0].contentRect.height + 50
        })
      }
    })
    return () => {
      resizeObserver.current && resizeObserver.current.disconnect()
    }
  }, [])
  useEffect(() => {
    setTimeout(() => {
      if (resizeObserver.current && roEl.current) {
        resizeObserver.current.observe(roEl.current)
      }
    }, 200)
  }, [edit.data])
  //#endregion

  // function getDisableTime(date: Moment | null) {
  //   console.log('end', edit.data.end_time)
  //   console.log('curr', date)

  //   if (!date || date.isAfter(moment(edit.data.end_time), 'day')) {
  //     return {
  //       disabledHours: () => [],
  //       disabledMinutes: () => [],
  //       disabledSeconds: () => []
  //     }
  //   } else {
  //     const beginReviewMin = moment(edit.data.end_time).add(1, 'hour')
  //     const brmHour = beginReviewMin.get('hour')
  //     const brmMinute = beginReviewMin.get('minute')
  //     const isCurrentSameAsBrmHour = brmHour === date.get('hour')
  //     console.log(brmHour, brmMinute)

  //     return {
  //       disabledHours: () => _.range(0, 24).filter(i => i < brmHour),
  //       disabledMinutes: () =>
  //         isCurrentSameAsBrmHour
  //           ? _.range(0, 60).filter(i => i < brmMinute)
  //           : [],
  //       disabledSeconds: () => []
  //     }
  //   }
  // }

  function onReworkClick() {
    const d = edit.data as Paper.PaperMarkingSettingFormData
    if (d.review_end_time) {
      if (moment(d.review_end_time).isBefore(moment())) {
        Modal.warning({ title: '已过阅卷结束时间，无法重置分配状态' })
        return
      }
    }
    confirm({
      title: '注意',
      content: '重置分配将导致批阅数据的丢失',
      icon: <ExclamationCircleOutlined />,
      okText: '重置分配状态',
      okButtonProps: { danger: true },
      onOk: async () => {
        await GET(
          APP_SCOPE.isInFanYa
            ? `/fanya/paper/${edit.data.id}/reset`
            : `/city/paper/${edit.data.id}/reset`,
          {}
        )
        Modal.success({ title: '重置成功' })
        setTimeout(() => {
          location.reload()
        }, 2000)
      }
    })
  }

  const [isRecheckLoading, setIsRecheckLoading] = useState(false)
  function onRecheckClick() {
    const modal = confirm({
      title: '确认发起补充分配',
      content: '分配需要一定时间，请耐心等待',
      icon: <ExclamationCircleOutlined />,
      okText: '补充分配',
      okButtonProps: { danger: true },
      onOk: async () => {
        if (isRecheckLoading) return
        setIsRecheckLoading(true)

        modal.update({
          okText: '分配中...',
          okButtonProps: { danger: true, loading: true }
        })

        try {
          await POST('/fanya/exam/paper/additional', {
            data: {
              exam_paper_id: edit.data.id
            }
          })
          Modal.success({ title: '分配完成' })
          setTimeout(() => {
            location.reload()
          }, 2000)
        } catch (e) {
          defaultErrorHandler(e as any)
        } finally {
          setIsRecheckLoading(false)
          modal.update({
            okText: '补充分配',
            okButtonProps: { danger: true, loading: false }
          })
        }
      }
    })
  }

  const [markingStatusDialogVisible, setMarkingStatusDialogVisible] = useState(
    false
  )

  function onExportClick() {
    requestAndSaveFile(
      `fanya/paper/${routeParams.pid}/students/history/all_export`,
      {}
    )
  }

  const [markingModeHelpVisible, setMarkingModeHelpVisible] = useState(false)

  // Setting sync
  const [settingSyncDialogVisible, setSettingSyncDialogVisible] = useState(
    false
  )
  function openSettingSyncDialog() {
    setSettingSyncDialogVisible(true)
  }

  if (!currentPaper) {
    return <Spin />
  }

  return (
    <div ref={roEl} className={'exam-paper-marking'}>
      <div>
        <div className='title' style={{ marginBottom: 8 }}>
          <Text strong>{currentPaper.name}</Text>
        </div>
        <div className='desc'>
          <Tag color={'success'}>{currentPaper.score}分</Tag>
          <Tag color={'success'}>{currentPaper.quest_count}题</Tag>
        </div>
        <Divider />

        <Alert
          message={
            <>
              配置须知：
              <div>
                •
                仅试卷为未分配状态，才可修改题组与判卷教师，若试卷已分配，则无法修改；
              </div>
              <div>
                • 若在考试结束前完成判卷设置，系统将在考试结束 <b>半小时</b>{' '}
                后开始启动试卷分配；
              </div>
              <div>
                • 若在考试结束半小时后完成判卷设置，系统将 <b>立即</b>{' '}
                启动试卷分配；
              </div>
              <div>
                • 请注意将开始阅卷时间设定到考试结束 <b>1-2个小时</b> 之后。
              </div>
            </>
          }
          style={{ marginBottom: 16 }}
        />
        {distributeStatus > PaperMarkingSettingDistributeStatus.PENDING ? (
          <Alert
            message={'当前试卷已分配，无法修改题组与判卷教师'}
            type={'warning'}
            showIcon
            style={{ marginBottom: 16 }}
          />
        ) : (
          <Alert
            message={'当前试卷未分配'}
            type={'warning'}
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <EditForm
          edit={edit}
          form={form}
          formProps={{ labelCol: { span: 3 }, onFinish: onSubmit }}
        >
          <div className='setting-hd'>基础配置</div>

          <Form.Item label={'判卷模式'}>
            <Form.Item
              name={'review_mode'}
              rules={[SelectRequiredRule]}
              noStyle
            >
              <Radio.Group
                name={'review_mode'}
                disabled={readonly}
                style={{ marginTop: 4 }}
                onChange={e => setCurrentMarkingMode(e.target.value)}
              >
                <Radio value={1}>流水批阅</Radio>
                <Radio value={3}>平均分模式</Radio>
                <Radio value={4}>仲裁批阅</Radio>
              </Radio.Group>
            </Form.Item>
            <Button
              type='link'
              icon={<QuestionCircleOutlined />}
              onClick={() => setMarkingModeHelpVisible(true)}
            >
              判卷模式说明
            </Button>
          </Form.Item>

          <Form.Item label={'判卷人员'}>
            <TeacherSelect />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            <QuestionBindingGroup
              markingMode={currentMarkingMode}
              readonly={readonly}
            />
          </Form.Item>
          <Form.Item
            label={'判卷开始'}
            name={'review_time'}
            rules={[SelectRequiredRule]}
          >
            <DatePicker
              showTime
              format={'YYYY-MM-DD HH:mm'}
              disabled={readonly}
            />
          </Form.Item>
          <Form.Item label={'判卷结束'} style={{ marginBottom: 0 }}>
            <Row gutter={8}>
              <Col flex={'0 0 200px'}>
                <Form.Item
                  name={'review_end_time'}
                  rules={[SelectRequiredRule]}
                >
                  <DatePicker
                    showTime
                    format={'YYYY-MM-DD HH:mm'}
                    disabledDate={curr =>
                      curr.isBefore(edit.params.review_time, 'day')
                    }
                    onChange={onReviewEndTimeChange}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                {reviewEndSubmitBtnVisible && (
                  <Button
                    type={'primary'}
                    style={{ marginLeft: 8 }}
                    onClick={onSubmitReviewEndTime}
                    loading={reviewEndSubmitLoading}
                  >
                    保存
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              <>
                保密设置
                <Tooltip title='自动从考试系统同步'>
                  <InfoCircleOutlined
                    style={{ marginLeft: '8px', color: '#1890ff' }}
                  />
                </Tooltip>
              </>
            }
          >
            <Form.Item
              name={'hide_info'}
              valuePropName={'checked'}
              style={{ marginBottom: 4 }}
            >
              <Checkbox disabled={true}>隐藏答卷人信息</Checkbox>
            </Form.Item>
            <Form.Item
              name={'show_answer_card'}
              valuePropName={'checked'}
              style={{ marginBottom: 4 }}
            >
              <Checkbox disabled={true}>显示原始答题卡</Checkbox>
            </Form.Item>
          </Form.Item>

          {/* 针对流水批阅模式 */}
          {currentMarkingMode === MarkingMode.GROUP_FLOW && (
            <>
              <Form.Item label='督查模式'>
                <Radio.Group
                  value={reviewModeEnable}
                  onChange={e => setReviewModeEnable(e.target.value)}
                  disabled={readonly}
                >
                  <Radio.Button value={true}>开启</Radio.Button>
                  <Radio.Button value={false}>关闭</Radio.Button>
                </Radio.Group>
                {reviewModeEnable && (
                  <Card style={{ marginTop: 8 }}>
                    <Form.Item
                      label={'判卷负责人'}
                      name='inspector'
                      rules={[SelectRequiredRule]}
                    >
                      <MultiTeacherListSelectorForFanya disabled={readonly} />
                    </Form.Item>
                    <Form.Item
                      label={'允许教师本人重评'}
                      name='change_score'
                      style={{ marginBottom: 0 }}
                    >
                      <Radio.Group disabled={readonly}>
                        <Radio value={1}>是</Radio>
                        <Radio value={0}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                )}
              </Form.Item>
            </>
          )}

          {typeof currentMarkingMode === 'number' &&
            [MarkingMode.AUDIT].includes(currentMarkingMode) && (
              <Form.Item label='仲裁配置'>
                <Card>
                  <Form.Item
                    label={'仲裁人员'}
                    labelCol={{ span: 3 }}
                    name={'judge'}
                    rules={[SelectRequiredRule]}
                  >
                    <TeacherSelectorForFanya disabled={readonly} />
                  </Form.Item>
                  <Form.Item
                    label={'仲裁分差'}
                    labelCol={{ span: 3 }}
                    name='judge_filter_by_question'
                    rules={[
                      {
                        type: 'array',
                        required: true,
                        min: 1,
                        message: '请至少添加一条分差设置'
                      }
                    ]}
                  >
                    <JudgeFilterFormItem />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        多人仲裁
                        <Tooltip title='可选'>
                          <InfoCircleOutlined
                            style={{ marginLeft: 8, color: '#1890ff' }}
                          />
                        </Tooltip>
                      </>
                    }
                    labelCol={{ span: 3 }}
                    name='judge_teacher_by_question'
                    rules={[
                      {
                        type: 'array'
                      }
                    ]}
                  >
                    <JudgeTeacherFormItem />
                  </Form.Item>
                </Card>
              </Form.Item>
            )}

          {edit.data.has_setting && (
            <Form.Item label={'分配状态'}>
              <Card>
                <div style={{ marginBottom: 24 }}>
                  <Alert
                    message={
                      {
                        [PaperMarkingSettingDistributeStatus.PENDING]: '未分配',
                        [PaperMarkingSettingDistributeStatus.RUNNING]: '分配中',
                        [PaperMarkingSettingDistributeStatus.DONE]: '分配完成'
                      }[distributeStatus]
                    }
                    type={'info'}
                    showIcon
                  ></Alert>
                  {distributeStatus ===
                    PaperMarkingSettingDistributeStatus.DONE && (
                    <div
                      style={{
                        padding: '2px 4px',
                        marginTop: '8px',
                        backgroundColor: '#f4f4f4'
                      }}
                    >
                      {
                        <Space size={'large'}>
                          <div>未参加: {edit.data.students?.empty ?? 0}</div>
                          <div>未交卷: {edit.data.students?.loading ?? 0}</div>
                          <div>已分配: {edit.data.students?.loaded ?? 0}</div>
                          <div>已批阅: {edit.data.students?.reviewed ?? 0}</div>
                        </Space>
                      }
                    </div>
                  )}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Button type={'primary'} danger onClick={onRecheckClick}>
                    补充分配
                  </Button>
                  <div style={{ marginTop: 4 }}>
                    <Typography.Text type={'secondary'}>
                      补充分配将获取最新答卷情况，并将当前已交卷的学生平均补充分配给批阅教师
                    </Typography.Text>
                  </div>
                </div>

                {distributeStatus ===
                  PaperMarkingSettingDistributeStatus.DONE && (
                  <div>
                    <Button type={'primary'} danger onClick={onReworkClick}>
                      重置分配状态
                    </Button>
                    <br />
                    <Typography.Text type={'secondary'}>
                      重置分配将会回到未分配状态，可重新设置阅卷教师，并重新拉取学生答卷进行分配
                    </Typography.Text>
                  </div>
                )}
              </Card>
            </Form.Item>
          )}

          {edit.data.has_setting &&
            distributeStatus === PaperMarkingSettingDistributeStatus.DONE && (
              <Form.Item label={'批阅情况'}>
                <Space>
                  <Button
                    type={'primary'}
                    onClick={() => setMarkingStatusDialogVisible(true)}
                  >
                    查看
                  </Button>
                  <Button onClick={onExportClick}>导出批阅历史</Button>
                </Space>
              </Form.Item>
            )}

          {edit.data.has_setting && !queryString.preview && (
            <Form.Item label='同步设置'>
              <Button type='primary' onClick={openSettingSyncDialog}>
                同步判卷配置到其他子试卷
              </Button>
              <Tooltip title='针对已配置完成的试卷生效'>
                <InfoCircleOutlined
                  style={{ marginLeft: '8px', color: '#1890ff' }}
                />
              </Tooltip>
            </Form.Item>
          )}

          {edit.data.id && (
            <MarkingStatusDialog
              paperId={edit.data.id as any}
              visible={markingStatusDialogVisible}
              onClose={() => setMarkingStatusDialogVisible(false)}
            />
          )}

          <div className='setting-hd'>其他配置</div>
          <Form.Item label='图片批注工具'>
            <Card>
              <Form.Item
                label='快速批注'
                name='inline_drawing_marking_mode'
                style={{ marginBottom: 0 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                help={
                  <div style={{ marginTop: 4 }}>
                    适合作答只有图片的试卷，如答题卡
                  </div>
                }
              >
                <Radio.Group buttonStyle='solid' disabled={readonly}>
                  <Radio.Button value={0}>关</Radio.Button>
                  <Radio.Button value={1}>开</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <div
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  fontSize: 16,
                  color: '#999'
                }}
              >
                分数批注相关
              </div>
              <Form.Item
                label='加减分模式'
                style={{ marginBottom: 16 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
              >
                <Row gutter={16}>
                  <Col flex='0 0 auto'>
                    <Form.Item name='score_plus_mode' noStyle>
                      <Radio.Group
                        buttonStyle='solid'
                        onChange={onScoreSignModeChange}
                      >
                        <Radio.Button value={0}>全部</Radio.Button>
                        <Radio.Button value={1}>只加分</Radio.Button>
                        <Radio.Button value={2}>只减分</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col>
                    {scoreSignModeSubmitBtnVisible && (
                      <Button
                        type='primary'
                        onClick={onScoreSignModeSubmit}
                        loading={scoreSignModeSubmitLoading}
                      >
                        保存
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label='自动算分'
                name='score_auto_count'
                style={{ marginBottom: 0 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                help={
                  <div style={{ marginTop: 4 }}>
                    批注自动算分可能与手动打分产生冲突，请谨慎使用
                  </div>
                }
              >
                <Radio.Group buttonStyle='solid' disabled={readonly}>
                  <Radio.Button value={0}>关</Radio.Button>
                  <Radio.Button value={1}>开</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Form.Item>

          {/* 按步打分设置 */}
          <StepScoreFormItem readonly={readonly} />

          <Form.Item label='填空题合并批阅'>
            <Form.Item name='question_type2_group_marking' noStyle>
              <Radio.Group
                buttonStyle='solid'
                disabled={
                  readonly ||
                  (!!currentMarkingMode &&
                    [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(
                      currentMarkingMode
                    ))
                }
              >
                <Radio.Button value={false}>关</Radio.Button>
                <Radio.Button value={true}>开</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {!!currentMarkingMode &&
              [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(
                currentMarkingMode
              ) && (
                <Tooltip title='平均分和仲裁模式下，暂不支持合并批阅'>
                  <InfoCircleOutlined
                    style={{
                      marginLeft: 8
                    }}
                  />
                </Tooltip>
              )}
          </Form.Item>

          <Form.Item label='教师自主拉取试卷' name='allow_teacher_supplement'>
            <Radio.Group buttonStyle='solid' disabled={readonly}>
              <Radio.Button value={0}>关</Radio.Button>
              <Radio.Button value={1}>开</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </EditForm>

        <div style={{ textAlign: 'right' }}>
          {readonly ? (
            <Button onClick={onCancel}>返回</Button>
          ) : (
            <Space>
              <Button onClick={onCancel}>取消</Button>
              <Button
                loading={edit.saving}
                type={'primary'}
                htmlType={'submit'}
                onClick={onSubmit}
              >
                保存
              </Button>
            </Space>
          )}
        </div>

        <Modal
          visible={markingModeHelpVisible}
          title='判卷模式'
          width={700}
          footer={null}
          onCancel={() => setMarkingModeHelpVisible(false)}
        >
          <div style={{ fontSize: 16 }}>
            <h2>平均分模式</h2>
            <p>
              每组题目由多组老师共同批改，每组老师独立批改当前题目所有试卷，组内老师平均分配试卷，同一组题目的多组阅卷老师数量需要相等
            </p>
            <h3>例如</h3>
            <p>
              当前考试有 1~10 号学生参加，待批改试卷 10
              份，由两组老师，每组三位，共六位老师批改，
              分组情况为【【A,B,C】【D,E,F】】，即：
            </p>
            <h4> A、B、C 三位老师共同批改 10 份：</h4>
            <ul>
              <li>A老师改 1,4,7,10</li>
              <li>B老师改 2,5,8</li>
              <li>C老师改 3,6,9</li>
            </ul>
            <h4>D、E、F 三位老师共同批改10份：</h4>
            <ul>
              <li>D老师改 1,4,7,10</li>
              <li>E老师改 2,5,8</li>
              <li>F老师改 3,6,9</li>
            </ul>
            <p>学生1 的平均分由 A、D老师都批改完成之后计算得到。</p>
            <h2>裁判模式</h2>
            <p>
              裁判模式在平均分模式的基础上增加了裁判和阈值配置，详见下方表单。
              <br />
              当两个老师的打分的分差大于当前阈值时，此题会被记录为待仲裁，并交由裁判批改。
            </p>
          </div>
        </Modal>

        <SettingSyncDialogVisible
          visible={settingSyncDialogVisible}
          onClose={() => setSettingSyncDialogVisible(false)}
        />
      </div>
    </div>
  )
}

export default () => (
  <MarkingSettingEditProvider>
    <MarkingSettingProvider>
      <Page />
    </MarkingSettingProvider>
  </MarkingSettingEditProvider>
)
